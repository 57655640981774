import React, { Component } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import queryString from "query-string";
import "./index.scss";

import { DateTimeFormatISO, calculateHoursAndMinutes, formatFilmShowDate, formatShowTime } from "../../utils/DateTimeFormat";

import * as API from "../../configuration/apiconfig";

import Loader from "../loader";
import Seats from "../../components/Seats";
import CustomSlider from "../../components/CustomSlider";
import Header from "../../components/Header";
import PurchaseFooter from "../../components/Footer";
import BookingFlow from "../../components/BookingFlow";
import { showAlert } from "../../components/showAlert";
import MovieInfo from "../../components/MovieInfo/MovieInfo";
import TicketSummary from "../../components/TicketSummary/TicketSummary";

import { ReactComponent as SeatTypeIcon } from "../../assets/images/seat-icons/seat-type-icon.svg";
import infoIcon from "../../assets/images/info-icon.svg";

import { craeteFnBPayloadForOrdersEndpoint } from "../../utils/PopulateFnbObject.js";

class index extends Component {
  constructor(props) {
    const parsedQueryString = queryString.parse(window.location.search);
    super(props);
    this.state = {
      sessionid: "",
      filmid: "",
      cinemaid: "",
      showdate: "",
      showtime: "",
      tz: "",
      filmData: [],
      filmDetails: {},
      showtimesData: [],
      experiences: [],
      seatsData: [],
      selectedSeats: [],

      applyToAllSeats: true,
      sessionId: null,
      open: false,
      isLoading: false,
      ticketTypes: [],

      wheelChairSeats: false,
      companionSeats: false,
      cinemaData: [],
      experiencesDetails: {},
      showTimes: [],
      selectedShowTime: null,
      selectedShowDate: null,
      selectedExperience: null,
      selectedCinema: null,
      selectedFilm: null,
      showDates: [],
      selectedTicketType: null,
      ticketsSummary: [],
      source: null,
      seatTypeAlertContent: [],
      selectedAlertContent: null,
      selectedSofaSeatGroupName: null,
      theatreStatus: false,
      theatreDisableText: "",
      sessionRunning: false,
      emptyTooltipData: {},
      maxSeatCount: 10,
      sofaSeatAlertShown: false,
      showChangeTicketTypeTooltip: false,
      changeTicketTypeTooltipShown: false,
      seatUnselected: false,
      experiencesList: [],
      ticketsDataForTicketSummary: [],
      allowSingleSeatSelection: process.env.REACT_APP_ALLOW_SINGLE_SOFA_SEAT_SELECTION == "true" ? true : false,
      getMaxSeatsData: process.env.REACT_APP_GET_MAX_SEATS_LENGTH_FROM_SITE_SETTINGS == "true" ? true : false,
      alertData: {},
      seat: {},
      iconBasedOnArea: JSON.parse(process.env.REACT_APP_ICONS_BASED_ON_AREA),
      fnbDataForTicketSummary: [],
      orderId: "",
      orderData: {},
      cart: [],
      experiencesString: "",
      showtimes: [],
      showTimer: false,
    };
    this.showtimeRefs = {};
  }

  componentDidMount() {
    window.winvar = this;
    const searchParam = new URLSearchParams(window.location.search);
    const sessionid = searchParam.get("sessionid");
    this.setState(
      {
        sessionid: sessionid,
      },
      () => {
        this.getSessionDetails();
      }
    );

    let orderDetails = JSON.parse(localStorage.getItem("orderId"));
    if (orderDetails) {
      if (orderDetails.expiry < Date.now()) {
        localStorage.removeItem("selectedSeats");
        localStorage.removeItem("orderId");
        orderDetails = null;
      } else {
        this.setState(
          {
            orderId: orderDetails.id,
            showTimer: true,
          },
          () => {
            this.getOrderData();
          }
        );
      }
    }

    let getCartData = JSON.parse(localStorage.getItem("cartData"));
    if (getCartData) {
      if (getCartData.expiry < Date.now()) {
        localStorage.removeItem("selectedSeats");
        localStorage.removeItem("cartData");
        localStorage.removeItem("orderId");
        getCartData = null;
      } else {
        // console.log(getCartData.cart, "cart data");
        this.setState({
          cart: getCartData.cart,
        });
      }
    }

    let getSelectedSeats = JSON.parse(localStorage.getItem("selectedSeats"));
    if (getSelectedSeats) {
      if (getSelectedSeats.expiry < Date.now()) {
        localStorage.removeItem("selectedSeats");
        localStorage.removeItem("orderId");
        getSelectedSeats = null;
        // this.setState({ sessionRunning: false });
      } else {
        this.setState(
          {
            selectedSeats: getSelectedSeats.value,
            // sessionRunning: true
          },
          () => {
            this.getTicketsSummary();
          }
        );
      }
      // getSelectedSeats = null;
    }
  }

  AbortTransactionResponse = (response) => {
    // This is dot net function
    console.log(response, 'Swipe card abort response--Seatlayout page');
  }

  GiftCardSwipeResponse = (response) => {
    // This is dot net function
    console.log(response, 'GiftCardSwipeResponse--Seatlayout page');
  }

  getShowTimes = async () => {
    let getShowDate = this.state.showtime.split("T")[0];
    const getShowData = await API.callEndpoint("GET", "Bearer", `/cms/v2/${this.state.filmid}/showtimes?showdate=${getShowDate}&cinemaid=${this.state.cinemaid}`).then((response) => {
      return response.data;
    });
    this.setState({
      // showtimesData: getShowData,
      showtimes: getShowData,
      isLoading: false,
    });

    // console.log("showtiemsData", getShowData)
  };

  getMovieDetails = async () => {
    const getFilmData = await API.callEndpoint("GET", "Bearer", `/cms/v2/films?id=${this.state.filmid}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        showAlert({
          html: `<p>${err.error}</p>`,
          iconHtml: `<img src=${infoIcon}>`,
          showCancelButton: false,
          cancelButtonText: "CANCEL",
          showConfirmButton: true,
          confirmButtonText: "Try Again",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconClasses: "borderless",
          popupClasses: "common-error-alert",
          onConfirm: () => {
            localStorage.removeItem("selectedSeats");
            localStorage.removeItem("orderId");
            document.cookie = "refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie = "accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie = "firstname=;max-age=0";
            document.cookie = "lastname=;max-age=0";
            window.location.href = `/movies`;
          },
          onCancel: () => {},
        });
      });

    this.setState({
      filmData: getFilmData?.length > 0 ? getFilmData : [],
      isLoading: false,
    });
  };

  getExperiencesDetails = async () => {
    let getExperienceId = this.state.showtimes.filter((item) => item.id == this.state.sessionid)[0].experiences[0];
    const getExperiences = await API.callEndpoint("GET", "Bearer", `/cms/v2/experiences?id=${getExperienceId}`).then((response) => {
      return response.data;
    });
    let experiencesString = getExperiences.map((item) => item.name).join(", ");
    let experiencesIdString = getExperiences.map((item) => item.id).join(", ");
    let showTimesDataTemp = this.state.showtimes.filter((item) => item.experiences.join(", ") === experiencesIdString);
    this.setState(
      {
        experiences: getExperiences,
        experiencesString: experiencesString,
        showtimesData: showTimesDataTemp,
        isLoading: false,
      },
      async () => {
        await this.setFilmDetails();
      }
    );
  };

  setFilmDetails = async () => {
    if (this.state.filmData && this.state.filmData.length > 0) {
      this.setState({
        filmDetails: {
          title: this.state.filmData[0].title,
          subtitle: this.state.filmData[0].subtitle,
          language: this.state.filmData[0].language,
          genres: this.state.filmData[0].genres,
          rating: this.state.filmData[0].rating,
          runtime: this.state.filmData[0].runtime,
          screenName: this.state.seatsData[0]?.screenName,
          images: this.state.filmData[0].images,
          experiences: this.state.experiencesString,
          timing: formatFilmShowDate(this.state.showdate),
          showTime: formatShowTime(this.state.showtime),
          urlSafeName: this.state.filmData[0]?.urlSafeName,
        },
      });
    }
  };

  getSeatLayout = async (resetCountDownTimer) => {
    // let filmDetails = this.state.filmDetails;
    let ticketTypes = [];
    this.setState({
      seatsData: [],
    });
    const getSeatsData = await API.callEndpoint("GET", "Bearer", `/order/v2/seats?sessionid=${this.state.sessionid}`)
      .then((response) => {
        if (response.data.length === 0) {
          showAlert({
            html: `<p>Sorry<span>Unable to load seat map. Please try again after sometime.</span></p>`,
            iconHtml: `<img src=${infoIcon}>`,
            showCancelButton: false,
            cancelButtonText: "CANCEL",
            showConfirmButton: true,
            confirmButtonText: "OK",
            allowOutsideClick: false,
            allowEscapeKey: false,
            iconClasses: "borderless",
            popupClasses: "common-error-alert",
            onConfirm: () => {
              // window.location.href = "/";
            },
            onCancel: () => {},
          });
        }
        let modifySeatsData = response.data;
        let selectedSeats = JSON.parse(localStorage.getItem("selectedSeats"));
        let orderDetails = JSON.parse(localStorage.getItem("orderId"));
        selectedSeats = selectedSeats?.value;
        orderDetails = orderDetails?.id;
        if (orderDetails != null && selectedSeats != null) {
          for (let i = 0; i < selectedSeats.length; i++) {
            for (let j = 0; j < modifySeatsData.length; j++) {
              for (let k = 0; k < modifySeatsData[j].rowdefs.length; k++) {
                for (let l = 0; l < modifySeatsData[j].rowdefs[k].seats.length; l++) {
                  let userselectedSeats = selectedSeats[i];
                  let responseSeats = modifySeatsData[j].rowdefs[k].seats[l];
                  if (userselectedSeats.style.toLowerCase() === "sofa") {
                    if (this.state.allowSingleSeatSelection) {
                      responseSeats.group.map((sofaSeat) => {
                        if (userselectedSeats.name == sofaSeat.name) {
                          sofaSeat.status = "Empty";
                        }
                      });
                    } else {
                      if (userselectedSeats.name == responseSeats.name && userselectedSeats.area == responseSeats.area) {
                        responseSeats.status = "Empty";
                      }
                    }
                  } else {
                    if (userselectedSeats.area == responseSeats.area && userselectedSeats.name == responseSeats.name) {
                      responseSeats.status = "Empty";
                    }
                  }
                }
              }
            }
          }
        } else {
          localStorage.getItem("selectedSeats") !== null && localStorage.removeItem("selectedSeats");
          localStorage.getItem("orderId") !== null && localStorage.removeItem("orderId");
        }
        modifySeatsData.forEach((seatType) => {
          ticketTypes.push(...seatType.tickettypes);
        });
        ticketTypes = Array.from(new Set(ticketTypes.map(JSON.stringify))).map(JSON.parse);
        return modifySeatsData;
      })
      .then((seatsResponse) => {
        this.setState(
          {
            seatsData: seatsResponse,
          },
          async () => {
            this.setState({ ticketTypes: ticketTypes });
            this.checkSeatTypesAvailability(seatsResponse);
            await this.getShowTimes();
            await this.getMovieDetails();
            await this.getExperiencesDetails();
            if (resetCountDownTimer) {
              // localStorage.getItem("endTimeInSecs") !== null && localStorage.removeItem("endTimeInSecs");
              // localStorage.getItem("BookingCountdown") !== null && localStorage.removeItem("BookingCountdown");
              // this.bookingCountdownTimer();
            }
          }
        );
      })
      .catch((ex) => {
        showAlert({
          html: `<p>Sorry<span>${ex.error}</span></p>`,
          iconHtml: `<img src=${infoIcon}>`,
          showCancelButton: false,
          cancelButtonText: "CANCEL",
          showConfirmButton: true,
          confirmButtonText: "Try again",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconClasses: "borderless",
          popupClasses: "common-error-alert",
          onConfirm: () => {
            window.location.href = `/?cinemaid=${localStorage.getItem("cinemaid")}`;
          },
          onCancel: () => {},
        });
      });
  };

  getSessionDetails = async () => {
    this.setState({
      isLoading: false,
    });
    const getSessionData = await API.callEndpoint("GET", "Bearer", `/cms/v2/sessiondetails?id=${this.state.sessionid}`)
      .then((response) => {
        return response.data;
      })
      .then((sessionResponse) => {
        if (sessionResponse.length) {
          this.setState(
            {
              filmid: sessionResponse[0].filmid,
              cinemaid: sessionResponse[0].cinemaid,
              showtime: sessionResponse[0].showtime,
              showdate: sessionResponse[0].showdate.split("T")[0],
              tz: sessionResponse[0].tz,
            },
            async () => {
              await this.getSeatLayout(false);
            }
          );
        } else {
          this.setState({
            isLoading: false,
          });
        }
      });
  };

  setSeatLayout = async (item) => {
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?sessionid=${item.id}`;
    window.history.replaceState({ path: newurl }, "", newurl);
    localStorage.setItem("sesstionid", item.id);
    this.setState(
      {
        sessionid: item.id,
        filmid: item.filmid,
        cinemaid: item.cinemaid,
        showtime: item.showtime,
        seatsData: [],
        selectedSeats: [],
        ticketTypes: [],
        ticketsSummary: [],
        ticketsDataForTicketSummary: [],
      },
      async () => {
        await this.getSeatLayout(true);
        await this.getExperiencesDetails();
      }
    );
  };

  handleSeatSelect = (seatObj) => {
    this.setState({
      selectedSeats: [...this.state.selectedSeats, seatObj],
    });
  };

  getTicketsSummary = (seatGrp) => {
    let ticketDetails = [];
    let subTotal = 0;
    let taxValue = 0;
    this.state.selectedSeats.map((item) => {
      const { allowSingleSeatSelection } = this.state;
      const isSofaSeat = item.style.toLowerCase() === "sofa";
      let findTicket = ticketDetails.filter((ticket) => ticket.description === item.ticketType.description && ticket.name != item.name);
      if (findTicket.length > 0) {
        findTicket[0].qty = !allowSingleSeatSelection && isSofaSeat ? findTicket[0].qty + 2 : findTicket[0].qty + 1;
        findTicket[0].seat = findTicket[0].seat + ", " + item.name;
        findTicket[0].valueBeforeTax = item.ticketType.valueBeforeTax * findTicket[0].qty;
        findTicket[0].seatCode = [...findTicket[0].seatCode, item.lockcode];
        subTotal = subTotal + item.ticketType.valueBeforeTax * (!allowSingleSeatSelection && isSofaSeat ? 2 : 1);
        taxValue = taxValue + item.ticketType.taxValue * (!allowSingleSeatSelection && isSofaSeat ? 2 : 1);
      } else {
        ticketDetails.push({
          description: item.ticketType.description,
          code: item.ticketType.code,
          qty: !allowSingleSeatSelection && isSofaSeat ? 2 : 1,
          valueBeforeTax: item.ticketType.valueBeforeTax * (!allowSingleSeatSelection && isSofaSeat ? 2 : 1),
          seat: item.name,
          seatCode: [item.lockcode],
          unitprice: item.ticketType.valueBeforeTax,
        });
        subTotal = subTotal + item.ticketType.valueBeforeTax * (!allowSingleSeatSelection && isSofaSeat ? 2 : 1);
        taxValue = taxValue + item.ticketType.taxValue * (!allowSingleSeatSelection && isSofaSeat ? 2 : 1);
      }
    });
    let sendTicketSummary = [];
    let ticketSummaryObject = new Object();
    ticketSummaryObject.ticketDetails = ticketDetails;
    ticketSummaryObject.subTotal = subTotal;
    ticketSummaryObject.taxValue = taxValue;
    ticketSummaryObject.grandTotal = subTotal + taxValue;
    ticketSummaryObject.bookingFee = sendTicketSummary.push(ticketSummaryObject);
    this.setState(
      {
        ticketsSummary: this.state.selectedSeats.length ? sendTicketSummary : [],
      },
      () => {
        if (this.state.ticketsSummary.length != 0) {
          var ticketsDataForTicketSummary = [];
          this.state.ticketsSummary[0].ticketDetails.map((eachTicketType) => {
            ticketsDataForTicketSummary.push({
              description: eachTicketType.description,
              qty: eachTicketType.qty,
              unitprice: eachTicketType.unitprice,
            });
          });
          this.setState({
            ticketsDataForTicketSummary,
          });
        } else {
          this.setState({
            ticketsDataForTicketSummary: [],
          });
        }
      }
    );
  };

  checkOrphanSeatSelection = () => {
    let seats = this.state.seatsData;
    let selectedSeats = this.state.selectedSeats;
    let seatsStatusString = {};
    let orphanSeatErrorMessage1 = "You cannot leave a single seat open between selected seats or next to an aisle";

    if (selectedSeats.length < 2) return;

    seats.map((seatData) => {
      seatData.rowdefs.map((row) => {
        seatsStatusString[row.name] = "";
        row.seats.map((seat) => {
          let seatSelected;
          if (seat.style.toLowerCase() === "passage") {
            seatsStatusString[row.name] = seatsStatusString[row.name] + "P";
            return;
          }
          if (seat.style.toLowerCase() === "wheelchair" || seat.style.toLowerCase() === "companion") {
            seatSelected = selectedSeats.filter((selectedSeat) => selectedSeat.name === seat.name)[0];
            if (seatSelected) {
              seatsStatusString[row.name] = seatsStatusString[row.name] + "S";
            } else {
              seatsStatusString[row.name] = seatsStatusString[row.name] + "E";
            }
            return;
          }
          if (seat.style.toLowerCase() === "sofa") {
            seat.group.forEach((sofaSeat) => {
              seatSelected = selectedSeats.filter((selectedSeat) => selectedSeat.name === sofaSeat.name)[0];
              if (sofaSeat.status.toLowerCase() === "sold" || sofaSeat.status.toLowerCase() === "broken") {
                seatsStatusString[row.name] = seatsStatusString[row.name] + "O";
              } else if (seatSelected) {
                seatsStatusString[row.name] = seatsStatusString[row.name] + "S";
              } else {
                seatsStatusString[row.name] = seatsStatusString[row.name] + "E";
              }
            });
            return;
          } else {
            seatSelected = selectedSeats.filter((selectedSeat) => selectedSeat.name === seat.name)[0];
            if (seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") {
              seatsStatusString[row.name] = seatsStatusString[row.name] + "O";
            } else if (seatSelected) {
              seatsStatusString[row.name] = seatsStatusString[row.name] + "S";
            } else {
              seatsStatusString[row.name] = seatsStatusString[row.name] + "E";
            }
          }
        });
        return row;
      });
      return seatData;
    });
    let emptyTooltipDataTemp = {};

    for (let row in seatsStatusString) {
      let selectedSeatCountPerRow = 0;
      for (let char in seatsStatusString[row]) {
        if (seatsStatusString[row][char] === "S") selectedSeatCountPerRow++;
      }
      if (
        seatsStatusString[row].includes("PES") &&
        !seatsStatusString[row].slice(0, seatsStatusString[row].indexOf("PES")).includes("E") &&
        !seatsStatusString[row].slice(0, seatsStatusString[row].indexOf("PES")).includes("O") &&
        !seatsStatusString[row].slice(0, seatsStatusString[row].indexOf("PES")).includes("S")
      ) {
        let orphanSeatId = [row] + (seatsStatusString[row].indexOf("PES") + 2);
        // console.log(seatsStatusString[row].includes("PES"), "PES", orphanSeatId, "orphanSeatId");
        emptyTooltipDataTemp = {
          status: true,
          id: orphanSeatId,
          errorMessage: orphanSeatErrorMessage1,
        };
      } else if (
        seatsStatusString[row].includes("SEP") &&
        !seatsStatusString[row].slice(seatsStatusString[row].indexOf("SEP") + 2).includes("E") &&
        !seatsStatusString[row].slice(seatsStatusString[row].indexOf("SEP") + 2).includes("O") &&
        !seatsStatusString[row].slice(seatsStatusString[row].indexOf("SEP") + 2).includes("S")
      ) {
        let orphanSeatId = [row] + (seatsStatusString[row].indexOf("SEP") + 2);
        // console.log(seatsStatusString[row].includes("PES"), "PES", orphanSeatId, "orphanSeatId");
        emptyTooltipDataTemp = {
          status: true,
          id: orphanSeatId,
          errorMessage: orphanSeatErrorMessage1,
        };
      } else if (selectedSeatCountPerRow > 1) {
        if (seatsStatusString[row].includes("SES")) {
          let orphanSeatId = [row] + (seatsStatusString[row].indexOf("SES") + 2);
          emptyTooltipDataTemp = {
            status: true,
            id: orphanSeatId,
            errorMessage: orphanSeatErrorMessage1,
          };
        } else if (!this.countEBetweenS(seatsStatusString[row])) {
          if (seatsStatusString[row].includes("OES")) {
            let orphanSeatId = [row] + (seatsStatusString[row].indexOf("OES") + 2);
            emptyTooltipDataTemp = {
              status: true,
              id: orphanSeatId,
              errorMessage: orphanSeatErrorMessage1,
            };
          } else if (seatsStatusString[row].includes("SEO")) {
            let orphanSeatId = [row] + (seatsStatusString[row].indexOf("SEO") + 2);
            emptyTooltipDataTemp = {
              status: true,
              id: orphanSeatId,
              errorMessage: orphanSeatErrorMessage1,
            };
          }
        }
      }

      this.setState({ emptyTooltipData: emptyTooltipDataTemp });
      if (emptyTooltipDataTemp.status) {
        break;
      }
    }

    return emptyTooltipDataTemp;
  };

  countEBetweenS = (str) => {
    let count = 0;
    let isBetweenS = false;

    for (let i = 0; i < str.length; i++) {
      if (str[i] === "S") {
        if (isBetweenS) {
          isBetweenS = false;
        } else {
          isBetweenS = true;
        }
      } else if (str[i] === "E" && isBetweenS) {
        count++;
      }
    }
    // console.log(count > 1);
    return count > 1;
  };

  continueNextStep = () => {
    const CheckOrphanSeat = process.env.REACT_APP_ALLOW_ORPHAN_SEAT_SELECTION;
    let orphanSeatsPresent;
    if (CheckOrphanSeat === "true") {
      orphanSeatsPresent = this.checkOrphanSeatSelection();
      if (orphanSeatsPresent?.status) return;
    }
    this.createOrder().then((order) => {
      if (!order) return;
      if (process.env.REACT_APP_SHOW_FNB_PAGE == "true") {
        window.location.href = `fnb?cinemaid=${this.state.cinemaid}&sessionid=${this.state.sessionid}&orderid=${order[0].id}`;
      } else {
        window.location.href = `payment?orderid=${order[0].id}`;
      }
    });
  };

  createOrder = async () => {
    let orderDetails = JSON.parse(localStorage.getItem("orderId"));
    if (orderDetails?.expiry && orderDetails.expiry < JSON.parse(localStorage.getItem("endTimeInSecs")) * 1000) {
      localStorage.removeItem("selectedSeats");
      localStorage.removeItem("orderId");
      orderDetails = null;
    }
    let getSeats = [];
    let getTicketTypes = [];
    this.state.ticketsSummary[0].ticketDetails.forEach((ticket) => {
      getSeats = [...getSeats, ...ticket.seatCode];
      getTicketTypes.push({ code: ticket.code, quantity: ticket.qty });
    });
    let fnbOrderData = [];
    if (this.state.cart.length > 0) {
      fnbOrderData = craeteFnBPayloadForOrdersEndpoint(this.state.cart);
    }

    let orderObject = new Object();
    orderObject.sessionid = this.state.sessionid;
    orderObject.fullname = "";
    orderObject.firstname = "";
    orderObject.lastname = "";
    orderObject.email = "";
    orderObject.phonenumber = "";
    orderObject.tickettypes = getTicketTypes;
    orderObject.seats = getSeats;
    orderObject.fnb = fnbOrderData;
    orderObject.id = orderDetails ? orderDetails.id : "";

    this.setState({
      isLoading: true,
    });
    const requestType = orderDetails ? "PUT" : "POST";
    const getOrderData = await API.callEndpoint(requestType, "Bearer", `/order/v2/orders`, orderObject)
      .then((response) => {
        return response.data;
      })
      .catch((ex) => {
        this.setState({
          isLoading: false,
        });
        showAlert({
          html: `<p>Sorry<span>${ex.error}</span></p>`,
          iconHtml: `<img src=${infoIcon}>`,
          showCancelButton: false,
          cancelButtonText: "CANCEL",
          showConfirmButton: true,
          confirmButtonText: "Try again",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconClasses: "borderless",
          popupClasses: "common-error-alert",
          onConfirm: () => {
            this.setState({ seatsData: [] });
            localStorage.removeItem("selectedSeats");
            localStorage.removeItem("orderId");
            this.getSeatLayout(false);
          },
          onCancel: () => {},
        });
      });
    if (!getOrderData) return;

    const expiry = orderDetails?.expiry ? orderDetails?.expiry : new Date().getTime() + 720 * 1000;
    let selectedSeatsObject = new Object();
    selectedSeatsObject.value = this.state.selectedSeats;
    selectedSeatsObject.expiry = expiry;
    localStorage.setItem("selectedSeats", JSON.stringify(selectedSeatsObject));
    let orderIdObject = new Object();
    orderIdObject.id = getOrderData[0].id;
    orderIdObject.expiry = expiry;
    localStorage.setItem("orderId", JSON.stringify(orderIdObject));
    this.setState({ orderData: getOrderData });
    return getOrderData;
  };

  handleShowtimeChange = (item) => {
    // let selectedSeats = this.state.selectedSeats;
    let selectedSeats = JSON.parse(localStorage.getItem("selectedSeats"));
    let orderId = this.state.orderId;
    if (selectedSeats?.value?.length > 0 && orderId != "") {
      showAlert({
        html: `<p>Changing showtime will cancel your current order, and you will need to start again. <span>Do you wish to change?</span></p>`,
        iconHtml: false,
        showCancelButton: true,
        cancelButtonText: "No",
        showConfirmButton: true,
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        allowEscapeKey: false,
        iconClasses: "borderless",
        popupClasses: "common-error-alert",
        reverseButtons: true,
        onConfirm: () => {
          let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?sessionid=${item.id}`;
          window.history.replaceState({ path: newurl }, "", newurl);
          localStorage.setItem("sesstionid", item.id);
          this.setState(
            {
              sessionid: item.id,
              filmid: item.filmid,
              cinemaid: item.cinemaid,
              showtime: item.showtime,
              seatsData: [],
              selectedSeats: [],
              ticketTypes: [],
              ticketsSummary: [],
              fnbDataForTicketSummary: [],
              cart: [],
              ticketsDataForTicketSummary: [],
              orderId: "",
              orderData: {},
            },
            async () => {
              if (this.showtimeRefs[item.showtime]) {
                this.showtimeRefs[item.showtime].scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "center",
                });
              }
              await this.cancelExistingOrder();
              this.setSeatLayout(item);
              localStorage.removeItem("selectedSeats");
              localStorage.removeItem("orderId");
              localStorage.removeItem("cartData");
              this.setState({ emptyTooltipData: {}, showTimer: false });
            }
          );
        },
        onCancel: () => {},
      });
    } else {
      this.setState(
        {
          seatsData: [],
          selectedSeats: [],
          emptyTooltipData: {},
          ticketTypes: [],
          ticketsDataForTicketSummary: [],
          orderId: "",
          orderData: {},
        },
        () => {
          this.setSeatLayout(item);
        }
      );
    }
  };

  checkSeatTypesAvailability = (seatsData) => {
    let wheelchairSeatsAvailable;
    let companionSeatsAvailable;
    let sofaSeatsAvailable;
    let premiumSeatsAvailable;
    seatsData.forEach((seatArea) => {
      for (let index = 0; index < seatArea.rowdefs.length; index++) {
        if (wheelchairSeatsAvailable && companionSeatsAvailable && sofaSeatsAvailable && premiumSeatsAvailable) break;
        const row = seatArea.rowdefs[index];
        for (let i = 0; i < row.seats.length; i++) {
          if (row.seats[i].style.toLowerCase() === "wheelchair") {
            // console.log("Wheelchair seats available");
            wheelchairSeatsAvailable = true;
          }
          if (row.seats[i].style.toLowerCase() === "companion") {
            // console.log("Companion seats available");
            companionSeatsAvailable = true;
          }
          if (row.seats[i].style.toLowerCase() === "sofa") {
            // console.log("Sofa seats available");
            sofaSeatsAvailable = true;
          }
          for (let j = 0; j < this.state.iconBasedOnArea.length; j++) {
            const iconType = this.state.iconBasedOnArea[j];
            if (iconType.area === row.seats[i].area && iconType.icon === "premium") {
              premiumSeatsAvailable = true;
            }
          }
        }
      }
    });
    this.setState({
      wheelChairSeats: wheelchairSeatsAvailable,
      companionSeats: companionSeatsAvailable,

      premiumSeats: premiumSeatsAvailable,
    });
  };

  updateOrderWithFnbData = () => {
    // console.log("hello");
  };

  backBtnClick = () => {
    if (localStorage.getItem("orderId") != null) {
      var orderDetails = JSON.parse(localStorage.getItem("orderId"));
      console.log("orderDetails >>>>> ", orderDetails);
      showAlert({
        html: `<p>Order cancellation alert!<span>Going back will cancel the current order. Do you want to continue?</span></p>`,
        iconHtml: `<img src=${infoIcon}>`,
        showCancelButton: true,
        cancelButtonText: "CANCEL",
        showConfirmButton: true,
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        iconClasses: "borderless",
        popupClasses: "common-error-alert",
        reverseButtons: true,
        onConfirm: () => {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              API.callEndpoint("DELETE", "Bearer", `/order/v2/orders/${orderDetails.id}`)
                .then((response) => {
                  this.setState(
                    {
                      selectedSeats: [],
                      isLoading: false,
                    },
                    () => {
                      localStorage.removeItem("selectedSeats");
                      localStorage.removeItem("orderId");
                      localStorage.removeItem("cartData");
                      localStorage.removeItem("sessionExpiredRedirectUrl");
                      document.cookie = "refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                      document.cookie = "accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                      document.cookie = "firstname=;max-age=0";
                      document.cookie = "lastname=;max-age=0";
                      window.location.href = `/login`;
                    }
                  );
                })
                .catch((err) => {
                  showAlert({
                    html: `<p>${err.error}</p>`,
                    iconHtml: `<img src=${infoIcon}>`,
                    showCancelButton: false,
                    cancelButtonText: "CANCEL",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    iconClasses: "borderless",
                    popupClasses: "common-error-alert",
                    onConfirm: () => {
                      localStorage.removeItem("selectedSeats");
                      localStorage.removeItem("orderId");
                      localStorage.removeItem("cartData");
                      localStorage.removeItem("sessionExpiredRedirectUrl");
                      document.cookie = "refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                      document.cookie = "accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                      document.cookie = "firstname=;max-age=0";
                      document.cookie = "lastname=;max-age=0";
                      window.location.href = `/login`;
                    },
                    onCancel: () => {},
                  });
                });
            }
          );
        },
      });
    } else {
      localStorage.removeItem("selectedSeats");
      localStorage.removeItem("orderId");
      localStorage.removeItem("cartData");
      localStorage.removeItem("sessionExpiredRedirectUrl");
      document.cookie = "refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      document.cookie = "accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      document.cookie = "firstname=;max-age=0";
      document.cookie = "lastname=;max-age=0";
      window.location.href = `/login`;
    }
  };

  getOrderData = () => {
    const { orderId } = this.state;
    API.callEndpoint("GET", "Bearer", `/order/v2/orders?id=${orderId}`).then((response) => {
      this.setState(
        {
          orderData: response.data[0],
        },
        () => {
          this.setFnbDataForTicketSummary();
        }
      );
    });
  };

  setFnbDataForTicketSummary = () => {
    var fnbDataForTicketSummary = [];
    this.state.orderData.order_grouping.map((eachItem) => {
      if (eachItem.type.toLowerCase() == "fnb") {
        var tempItemObject = {
          description: eachItem.description,
          qty: eachItem.quantity,
          unitprice: eachItem.unitprice - eachItem.taxValue / eachItem.quantity,
          modifiers: [],
          smartModifiers: [],
        };

        eachItem.additionalInfo.modifiers.map((eachModifier) => {
          var tempModifierObject = {
            description: eachModifier.description,
            qty: eachModifier.quantity,
            unitprice: eachModifier.pricebeforetax / eachModifier.quantity,
          };
          tempItemObject.modifiers.push(tempModifierObject);
          tempItemObject.unitprice = tempItemObject.unitprice - eachModifier.pricebeforetax;
        });
        fnbDataForTicketSummary.push(tempItemObject);
      }
    });
    this.setState({
      fnbDataForTicketSummary,
    });
  };

  cancelExistingOrder = async () => {
    let orderDetails = JSON.parse(localStorage.getItem("orderId"));
    if (orderDetails) {
      await API.callEndpoint("DELETE", "Bearer", `/order/v2/orders/${orderDetails.id}`)
        .then((res) => console.log("order cancelled"))
        .catch((err) => {
          showAlert({
            html: `<p>${err.error}</p>`,
            iconHtml: `<img src=${infoIcon}>`,
            showCancelButton: false,
            cancelButtonText: "CANCEL",
            showConfirmButton: true,
            confirmButtonText: "Try again",
            allowOutsideClick: false,
            allowEscapeKey: false,
            iconClasses: "borderless",
            popupClasses: "common-error-alert",
            onConfirm: () => {
              this.setState({ seatsData: [] });
              localStorage.removeItem("selectedSeats");
              localStorage.removeItem("orderId");
              this.getSeatLayout(false);
            },
            onCancel: () => {},
          });
        });
    }
  };

  setSelectedSeats = (selectedSeats) => {
    this.setState({ selectedSeats });
  };

  clearEmptyTooltipData = () => {
    this.setState({ emptyTooltipData: {} });
  };

  render() {
    const { title, rating, genres, language, timing, subtitle, runtime, experiences, screenName, urlSafeName } = this.state.filmDetails;
    const posterImg = this.state.filmDetails.images?.find((x) => x.code === "x250y350")?.imageurl;
    return (
      <>
        <Loader loader={this.state.isLoading} />
        <section className="seatlayout-container">
          <Header logo={true} homeIcon={false} timer={this.state.showTimer} />
          {Object.keys(this.state.filmDetails).length == 0 ? (
            <div className="movieInfoSkeletalLoader">
              <Skeleton height="350px" width="250px" borderRadius={8} containerClassName="showtime-skeleton" baseColor="#24272A" highlightColor="#38352D" />
              <div className="movieDetailsSkeletonDiv">
                <Skeleton height="48px" borderRadius={8} containerClassName="showtime-skeleton movie" baseColor="#24272A" highlightColor="#38352D" />
                <Skeleton height="30px" borderRadius={8} containerClassName="showtime-skeleton rating" baseColor="#24272A" highlightColor="#38352D" />
                <Skeleton height="30px" borderRadius={8} containerClassName="showtime-skeleton selectedData" baseColor="#24272A" highlightColor="#38352D" />
              </div>
            </div>
          ) : (
            <MovieInfo filmsData={this.state.filmDetails} selectedSeats={this.state.selectedSeats} />
          )}

          <section className="seatlayout-wrap">
            <BookingFlow currentStep="1" />
          </section>

          <section className="showtimes-wrap">
            {this.state.showtimesData.length > 0 ? (
              <CustomSlider showArrows={false}>
                {this.state.showtimesData.map((item) => {
                  let setTime = DateTimeFormatISO(item.showtime, "TimeDefault");
                  let getTime = DateTimeFormatISO(this.state.showtime, "TimeDefault");
                  let isActive = setTime === getTime && item.id == this.state.sessionid;
                  var ticketstatus =
                    item.ticketstatus == "Available" ? "Available" : item.ticketstatus == "Fast Filling" ? "fastFillingShowtime" : item.ticketstatus == "Sold Out" ? "soldOutShowTime" : "";
                  return (
                    <li className={`play-date-wrap ${isActive ? "active" : ""}`} ref={(el) => (this.showtimeRefs[item.showtime] = el)}>
                      <a
                        className="play-time"
                        href="javascript:;"
                        style={{
                          pointerEvents: isActive || ticketstatus == "soldOutShowTime" || this.state.seatsData.length < 1 ? "none" : "auto",
                        }}
                        onClick={() => {
                          this.handleShowtimeChange(item, ticketstatus);
                        }}
                      >
                        {setTime}
                      </a>
                    </li>
                  );
                })}
              </CustomSlider>
            ) : (
              <SkeletonTheme baseColor="#d6d6d6" highlightColor="#ccc">
                <Skeleton height="54px" width="144px" borderRadius={15} count="7" containerClassName="showtime-skeleton" />
              </SkeletonTheme>
            )}
          </section>

          <section
            className="movie-type"
            style={{
              display: `${this.state.selectedSeats.length > 0 ? "" : "none"}`,
            }}
          >
            <div className="std-type">
              <SeatTypeIcon />
              <span className="seat-count">
                {this.state.selectedSeats.map((item, index) => (
                  <>
                    {item.name}
                    {index < this.state.selectedSeats.length - 1 ? ", " : ""}
                  </>
                ))}
              </span>
            </div>
          </section>
          <Seats
            seatsData={this.state.seatsData}
            selectedSeats={this.state.selectedSeats}
            showCompanionSeats={this.state.companionSeats}
            showWheelChairSeats={this.state.wheelChairSeats}
            showPremiumSeats={this.state.premiumSeats}
            getMaxSeatsData={this.state.getMaxSeatsData}
            allowSingleSeatSelection={this.state.allowSingleSeatSelection}
            emptyTooltipData={this.state.emptyTooltipData}
            getTicketsSummary={this.getTicketsSummary}
            clearEmptyTooltipData={this.clearEmptyTooltipData}
            setSelectedSeats={this.setSelectedSeats}
          />
          {Object.keys(this.state.filmDetails).length > 0 && (
            <PurchaseFooter
              fnbDataForTicketSummary={this.state.fnbDataForTicketSummary}
              ticketsDataForTicketSummary={this.state.ticketsDataForTicketSummary}
              removeItemFromCart={false}
              incDecItem={false}
              updateOrderWithFnbData={this.updateOrderWithFnbData}
              nextBtnClick={this.continueNextStep}
              nextBtnText="CONTINUE"
              nextBtnDisable={this.state.ticketsDataForTicketSummary.length == 0 ? true : false}
              backBtnClick={this.backBtnClick}
            ></PurchaseFooter>
          )}

          <section class="offcanvas offcanvas-bottom ticket-summary-modal" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
            <div className="summary-scroll">
              <div class="offcanvas-header">
                <a href="javascript:;" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></a>
              </div>
              <aside className="movie-detail-wrap">
                <img src={posterImg} alt="Movie Poster" className="poster-img" />
                <aside className="movie-detail">
                  <h2 className="movie-title">{title}</h2>
                  <div className="movie-desc">
                    <p>
                      <span>{rating}</span>
                      <span>
                        {genres?.length > 0 &&
                          genres.map((actionData, index) => (
                            <React.Fragment key={index}>
                              {actionData}
                              {genres.length - 1 !== index && ", "}
                            </React.Fragment>
                          ))}
                      </span>
                      {language && <span>Language - {language}</span>}
                      <span>{calculateHoursAndMinutes(runtime)}</span>
                    </p>
                    <p>
                      {subtitle && <span>Subtitle - {subtitle}</span>}
                      <span>{timing}</span>
                      <span>{experiences}</span>
                      <span>{screenName}</span>
                    </p>
                    <p>
                      {this.state.showtime && <span>{formatShowTime(this.state.showtime)}</span>}
                      {this.state.selectedSeats.length > 0 && (
                        <span>
                          {this.state.selectedSeats.length > 1
                            ? "Seats - "
                            : this.state.selectedSeats[0].style.toLowerCase() === "sofa" && !this.state.allowSingleSeatSelection
                            ? "Seats - "
                            : "Seat - "}
                          {this.state.selectedSeats.map((seat, index) => (
                            <React.Fragment key={index}>
                              {seat.name}
                              {index < this.state.selectedSeats.length - 1 && ", "}
                            </React.Fragment>
                          ))}
                        </span>
                      )}
                    </p>
                  </div>
                </aside>
              </aside>
              <TicketSummary ticketsSummary={this.state.ticketsSummary} />
              <div className="continue-wrap">
                <a href="javascript:;" className={`continue-btn ${this.state.ticketsSummary.length > 0 ? "" : "disabled"}`} onClick={() => this.continueNextStep()}>
                  Continue
                </a>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}

export default index;
