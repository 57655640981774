import React, { Component } from "react";
import "../../styles/common.scss";
import "./index.scss";
import * as API from "../../configuration/apiconfig";
import Loader from "../loader";
import MovieInfo from "../../components/MovieInfo/MovieInfo";
import IconInfo from "../../assets/images/icons/iconInfo.svg";
import Header from "../../components/Header";
import CustomModal from "../../components/CustomModal";
import PurchaseFooter from "../../components/Footer";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      showDateActiveState: 0,
      filmsData: {},
      datesData: [],
      cinemasData: [],
      experienceData: [],
      showTimeData: [],
      // filteredExperiencesForSelectedShowDate: []
      filteredExperiences: [],
      filteredShowtimes: [],
      selectedExp: {},
      selectedSessionID: "",
      consumerAdviceModalSH: false,
      consumerAdviceData: {
        title: "Consumer Advice",
        description: ["I agree to comply with ID Requirements.", "I agree to strictly adhere the Landmark Cinemas Code of Conduct."],
        descriptions: [
          {
            rating: "R",
            description: "Restricted to 18 years of age and over.",
          },
          {
            rating: "18A",
            description: "Must be 18 years of age or older to purchase. Persons under 18 should view with an adult.",
          },
        ],
      },
      futureDateCaution: false,
    };
  }

  componentDidMount = () => {
    window.winvar = this;
    this.getFilms();
    localStorage.removeItem("sesstionid");
    localStorage.removeItem("sessionExpiredRedirectUrl");
    localStorage.removeItem("endTimeInSecs");
    localStorage.removeItem("BookingCountdown");
    console.log(this.checkIfRatingMessageHasToBeShown("R"));
    console.log(this.checkIfRatingMessageHasToBeShown("18A"));
    console.log(this.checkIfRatingMessageHasToBeShown("PG"));
    if (localStorage.getItem("orderId") != null && localStorage.getItem("giftCardSwipeInit") != null) {
      var orderDetails = JSON.parse(localStorage.getItem("orderId"));
      if (orderDetails?.id) {
        localStorage.removeItem("giftCardSwipeInit");
        window.callbackObj.callAbortTransaction(orderDetails.id, "failure", "Sorry", "Swipe cancelled");
      }

      this.deleteOrder();
      localStorage.removeItem("orderId");
    }
  };

  AbortTransactionResponse = (response) => {
    // This is dot net function
    console.log(response, "Swipe card abort response--showtime page");
  };

  GiftCardSwipeResponse = (response) => {
    // This is dot net function
    console.log(response, "GiftCardSwipeResponse--showtime page");
  };

  deleteOrder = () => {
    var orderDetails = JSON.parse(localStorage.getItem("orderId"));
    if (orderDetails?.id) {
      API.callEndpoint("DELETE", "Bearer", `/order/v2/orders/${orderDetails.id}`)
        .then((response) => {
          console.log(response);
        })
        .catch((ex) => {
          console.log(ex.error);
        });
    }
  };

  getFilms = async () => {
    this.setState(
      {
        loader: true,
      },
      () => {
        const urlParams = new URLSearchParams(window.location.search);
        const getMovieName = urlParams.get("title");
        // const films = await API.callEndpoint("GET", "Bearer", `/cms/v2/films?urlsafetitle=${getMovieName}`);
        API.callEndpoint("GET", "Bearer", `/cms/v2/films?urlsafetitle=${getMovieName}`).then((response) => {
          this.setState(
            {
              filmsData: response.data[0],
            },
            () => {
              var ratingDescriptionFirstLineIndex = this.state.consumerAdviceData.descriptions.findIndex((x) => x.rating == this.state.filmsData.rating);
              if (ratingDescriptionFirstLineIndex != -1) {
                var description = this.state.consumerAdviceData.description;
                description.unshift(this.state.consumerAdviceData.descriptions[ratingDescriptionFirstLineIndex].description);
                this.setState({
                  consumerAdviceData: { ...this.state.consumerAdviceData, description },
                });
              }
              this.getShowDate();
            }
          );
        });
      }
    );
  };

  getShowDate = () => {
    API.callEndpoint("GET", "Bearer", `/cms/v2/distinctshowdates?filmid=${this.state.filmsData.id}`).then((response) => {
      this.setState(
        {
          datesData: response.data,
        },
        () => {
          // // // // // this.getCinemas(); // No need for CinemasData.
          this.allExperiences();
        }
      );
    });
  };

  getCinemas = () => {
    API.callEndpoint("GET", "Bearer", "/cms/v2/cinemas").then((response) => {
      this.setState(
        {
          cinemasData: response.data,
        },
        () => {
          this.allExperiences();
        }
      );
    });
  };

  allExperiences = async () => {
    API.callEndpoint("GET", "Bearer", "/cms/v2/experiences").then((response) => {
      this.setState(
        {
          experienceData: response.data,
        },
        () => {
          // this.state.datesData.length > 0 && this.showtimesLoadOnClick(this.state.datesData[0].showdate.split("T")[0]);
          if (this.state.datesData.length > 0) {
            this.showtimesLoadOnClick(this.state.datesData[0].showdate.split("T")[0]);
            this.handleFutureDateSelection(this.state.datesData[0].showdate.split("T")[0]);
          }
        }
      );
    });
  };

  showtimesLoadOnClick = async (selectedDate) => {
    this.setState(
      {
        loader: true,
      },
      () => {
        API.callEndpoint("GET", "Bearer", `/cms/v2/${this.state.filmsData.id}/showtimes?showdate=${selectedDate}`).then((response) => {
          this.setState(
            {
              showTimeData: response.data,
              loader: false,
            },
            () => {
              this.filterExperiencesFromShowTime();
              this.filterShowTimeFromExperiences();
            }
          );
        });
      }
    );
  };

  filterExperiencesFromShowTime = async () => {
    var getCinemaIdFromLocalstorage = localStorage.getItem("cinemaid");
    var getExpObj = this.state.showTimeData.filter((x) => x.cinemaid == getCinemaIdFromLocalstorage);
    const experiences = this.state.experienceData.filter((exp) => {
      return getExpObj.find((data) => data.experiences[0] === exp.id);
    });
    this.setState({
      filteredExperiences: experiences,
    });
  };

  filterShowTimeFromExperiences = () => {
    var getCinemaIdFromLocalstorage = localStorage.getItem("cinemaid");
    var getShowtimeObj = this.state.showTimeData.filter((x) => x.cinemaid == getCinemaIdFromLocalstorage);
    this.setState({
      filteredShowtimes: getShowtimeObj,
    });
  };

  // moviesPosterRender = () => {
  //   var tempFilmsData = this.state.filmsData;
  //   var renderTags = "";
  //   {
  //     renderTags =
  //       Object.keys(tempFilmsData).length > 0 ? (
  //         <div className="movie-detail-wrap">
  //           {tempFilmsData.images.length > 0 ? (
  //             <img
  //               src={
  //                 tempFilmsData.images.find((x) => x.code === "x280y420")
  //                   .imageurl
  //               }
  //               alt={tempFilmsData.title}
  //               className="poster-img"
  //             />
  //           ) : null}
  //           {/* <img src={Poster} alt={tempFilmsData.title} className="poster-img" /> */}

  //           <aside className="movie-detail">
  //             <h1 className="movie-title">{tempFilmsData.title}</h1>
  //             <div className="movie-desc">
  //               <div>
  //                 <span>{tempFilmsData.rating}</span>
  //                 {tempFilmsData.genres.length > 0 ? (
  //                   <span>
  //                     {tempFilmsData.genres.map((actionData, index) => {
  //                       return (
  //                         <>
  //                           {actionData}
  //                           {tempFilmsData.genres.length - 1 == index
  //                             ? ""
  //                             : ", "}
  //                         </>
  //                       );
  //                     })}
  //                   </span>
  //                 ) : null}
  //                 {tempFilmsData.language && (
  //                   <span>Language - {tempFilmsData.language}</span>
  //                 )}
  //                 <span>{tempFilmsData.runtime} min</span>
  //                 {tempFilmsData.subtitle && (
  //                   <span>Subtitle - {tempFilmsData.subtitle}</span>
  //                 )}
  //               </div>
  //             </div>
  //             {tempFilmsData.cast ? (
  //               <p className="movie-cast">
  //                 <span className="font-bold">Cast</span>
  //                 {tempFilmsData.cast}
  //               </p>
  //             ) : null}
  //             {tempFilmsData.synopsis ? (
  //               <p className="movie-synopsis">
  //                 <span className="font-bold">Synopsis</span>
  //                 {tempFilmsData.synopsis}
  //               </p>
  //             ) : null}
  //           </aside>
  //         </div>
  //       ) : null;
  //   }
  //   return renderTags;
  // };

  datesRender = () => {
    var tempDatesData = this.state.datesData;
    var datesTagsRender = "";
    {
      datesTagsRender =
        tempDatesData.length > 0
          ? tempDatesData.map((dateDatas, index) => {
              return (
                <li onClick={(event) => this.showDatesOnClick(event, dateDatas.showdate.split("T")[0], index)} className={`movie-date-wrap ${index == this.state.showDateActiveState ? "today" : ""}`}>
                  {this.populateDataString(dateDatas.showdate, dateDatas.day)}
                </li>
              );
            })
          : null;
    }
    return datesTagsRender;
  };

  populateDataString = (showDateItem, dayName) => {
    // console.log("showDateItem - " + showDateItem);
    // var showdateStr = showDateItem.split("T")[0];
    // showdateStr = showdateStr.split("-").join("");
    // console.log("showdateStr - >" + showdateStr);
    // var currentDates = new Date().toJSON();
    // console.log("currentDates");
    // console.log(new Date());
    // currentDates = currentDates.split("T")[0];
    // var dayStr = this.getMonthDays(showDateItem, "monthField") + " " + showDateItem.split("T")[0].split("-")[2];
    // return (
    //   <>
    //     <div className="movie-date">{showdateStr.toString() === currentDates.toString() ? "Today" : dayStr}</div>
    //     <div className="movie-day">{showdateStr.toString() === currentDates.toString() ? dayStr : this.getMonthDays(showDateItem, "dayField")}</div>
    //   </>
    // );

    var showDate = showDateItem.split("T")[0];
    var currentDates = new Date().toString().split(" ");
    var currentDateString = currentDates[3] + "-" + this.getMonthInNumber(currentDates[1]) + "-" + currentDates[2];
    var dayStr = this.getMonthDays(showDateItem, "monthField") + " " + showDateItem.split("T")[0].split("-")[2];
    // // // // // // // // // // var dayName = new Date(showDateItem).toString().split(" ")[0];
    return (
      <>
        <div className="movie-date">{showDate === currentDateString ? "Today" : dayStr}</div>
        <div className="movie-day">{dayName.slice(0, 3)}</div>
      </>
    );
  };

  getMonthInNumber = (monthInString) => {
    var monthNameArray = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var monthNumber = monthNameArray.indexOf(monthInString) + 1;
    return monthNumber < 10 ? "0" + monthNumber : monthNumber;
  };
  getMonthDays = (getShowDate, getFields) => {
    if (getFields === "monthField") {
      var getMonth = getShowDate.split("T")[0].split("-")[1];
      var monthNameArray = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
      return monthNameArray[getMonth - 1];
    } else if (getFields === "dayField") {
      var dayName = new Date(getShowDate);
      var weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return weekday[dayName.getDay()];
    }
  };

  showDatesOnClick = (event, selectedDate, getIndex) => {
    var currentEle = event.currentTarget;
    setTimeout(() => {
      currentEle &&
        currentEle.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
    }, 250);
    this.setState(
      {
        showDateActiveState: getIndex,
      },
      () => {
        this.handleFutureDateSelection(selectedDate);
        this.showtimesLoadOnClick(selectedDate);
      }
    );
  };

  handleFutureDateSelection = (selectedDate) => {
    // Caution Message - Selecting future date.
    // console.log("handleFutureDateSelection()");
    // console.log("handleFutureDateSelection() > selectedDate", selectedDate);
    var currentDates = new Date().toJSON();
    // console.log("handleFutureDateSelection() > currentDates", currentDates);
    // var currentDates = moment().tz(tz);
    // currentDates = currentDates.format().split("T")[0];
    currentDates = currentDates.split("T")[0];
    var currentDateArr = currentDates.split("-");
    var currentDatestr = parseInt(currentDateArr.join(""));
    var selectedFilmShowDateArr = selectedDate.split("-");
    var selectedFilmShowDateStr = parseInt(selectedFilmShowDateArr.join(""));
    // console.log("handleFutureDateSelection() > currentDatestr", currentDatestr);
    // console.log("handleFutureDateSelection() > selectedFilmShowDateStr", selectedFilmShowDateStr);
    this.setState({
      futureDateCaution: selectedFilmShowDateStr > currentDatestr ? true : false,
    });
  };

  handleExpModal = (data) => {
    let selectedExpImageUrl = data.images.filter((x) => x.code === "x748y475")[0]?.imageurl;
    let selectedExpLogoUrl = data.images.filter((x) => x.code === "x330y50")[0]?.imageurl;
    this.setState({
      selectedExp: {
        ...data,
        expImageUrl: selectedExpImageUrl,
        expLogoUrl: selectedExpLogoUrl,
      }, // Assuming experiences are in the data object
    });
  };

  checkIfRatingMessageHasToBeShown = (rating) => {
    var ratingsArray = this.state.consumerAdviceData.descriptions.map((item) => item.rating);
    return ratingsArray.includes(rating);
  };
  showTimesRender = () => {
    var tempFilteredShowtimes = this.state.filteredShowtimes;
    var tempFilteredExperiences = this.state.filteredExperiences;
    var showTimeTagsRender = "";
    if (tempFilteredExperiences.length > 0 && tempFilteredShowtimes.length > 0) {
      showTimeTagsRender = tempFilteredExperiences.map((expData) => {
        return (
          <section className="play-wrap">
            <div className="play-icon-wrap">
              <h2>{expData.name}</h2>
              <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#showTimeModal" onClick={() => this.handleExpModal(expData)}>
                <img src={IconInfo} alt="Info" />
              </a>
            </div>
            <ul>
              {tempFilteredShowtimes.map((showTimeData) => {
                return (
                  showTimeData.experiences[0] == expData.id && (
                    // class for showtime.
                    // Default / available => "play-date-wrap showtimeAvailable"
                    // Fast Filling => "play-date-wrap showtimeFastFilling"
                    // Sold => "play-date-wrap showtimeSold"
                    // Disabled => "play-date-wrap showtimeDisabled"
                    <li className="play-date-wrap">
                      {this.checkIfRatingMessageHasToBeShown(this.state.filmsData.rating) ? (
                        <a
                          className="play-time"
                          href="javascript:;"
                          onClick={() => {
                            this.setState(
                              {
                                selectedSessionID: showTimeData.id,
                                consumerAdviceModalSH: true,
                              },
                              () => {
                                localStorage.setItem("sesstionid", this.state.selectedSessionID);
                              }
                            );
                          }}
                        >
                          {this.formatShowTime(showTimeData.showtime)}
                        </a>
                      ) : (
                        <a className="play-time" href="javascript:;" onClick={() => this.handleRredirectToSeatLayoutPage(showTimeData.id)}>
                          {this.formatShowTime(showTimeData.showtime)}
                        </a>
                      )}
                    </li>
                  )
                );
              })}
            </ul>
          </section>
        );
      });
    } else {
      if (!this.state.loader) {
        showTimeTagsRender = (
          <aside className="showtime-not">
            <p>Showtimes are coming soon! Tickets for this film are not yet available.</p>
          </aside>
        );
      }
    }
    return showTimeTagsRender;
  };

  formatShowTime = (time) => {
    var actualShowTime = time.split("T")[1].split(":");
    var hours = actualShowTime[0];
    hours = hours > 12 ? hours - 12 : hours;
    hours = ("0" + hours).slice(-2);

    var minutes = actualShowTime[1];
    minutes = ("0" + minutes).slice(-2);

    var meridian = actualShowTime[0] >= 12 ? "PM" : "AM";
    return hours + ":" + minutes + " " + meridian;
  };

  handleRredirectToSeatLayoutPage = (sessionid) => {
    this.setState(
      {
        selectedSessionID: sessionid,
      },
      () => {
        // window.location.href = `/seatlayout?sessionid=${this.state.selectedSessionID}`;
        localStorage.setItem("sesstionid", this.state.selectedSessionID);
        window.location.href = `/login`;
      }
    );
  };

  iAgreeBtnClick = () => {
    window.location.href = `/login`;
  };

  cancelBtnClick = () => {
    this.setState({ consumerAdviceModalSH: false });
  };

  backBtnClick = () => {
    console.log("backBtnClick");

    window.location = "/movies";
  };

  render() {
    return (
      <>
        <Loader loader={this.state.loader} />
        <section className="showtime-container">
          <Header logo={true} homeIcon={true} timer={false} />
          {/* <section className="banner-wrap">
            <img src={MaskIcon} alt="Background Poster" className="bg-banner" />
            {this.moviesPosterRender()}
          </section> */}
          <MovieInfo filmsData={this.state.filmsData} />
          <section className="time-wrap">
            <ul id="date-list">{this.datesRender()}</ul>
            {this.state.futureDateCaution ? (
              <section className="futureDateCaution">
                <p>Caution: The selected date is in the future.</p>
              </section>
            ) : (
              ""
            )}
          </section>
          <section className="show-times-sec">{this.showTimesRender()}</section>
          <PurchaseFooter
            fnbDataForTicketSummary={[]}
            ticketsDataForTicketSummary={[]}
            removeItemFromCart={false}
            incDecItem={false}
            backBtnClick={this.backBtnClick}
            nextBtnClick={this.continueNextStep}
            nextBtnText=""
            nextBtnDisable={true}
          ></PurchaseFooter>
          {/* <section className="footer">
            <Link to={`/movies`} title="Back" className="back-link">
              Back
            </Link>
          </section> */}
        </section>
        <div className="modal fade showtime-modal" id="showTimeModal" tabindex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <a href="javascript:;" className="modal-close" data-bs-dismiss="modal" aria-label="Close"></a>
              <div className="modal-body">
                {this.state.selectedExp.expImageUrl && (
                  <div className="modal-banner">
                    <img src={this.state.selectedExp.expImageUrl} alt="Banner Image" />
                  </div>
                )}
                <div className="movie-info-modal">
                  {this.state.selectedExp.expLogoUrl ? (
                    <div className="modal-banner">
                      <img src={this.state.selectedExp.expLogoUrl} alt="Experience Logo" className="exp-icon" />
                    </div>
                  ) : (
                    <p className="experinceHeading">{this.state.selectedExp.name}</p>
                  )}
                  <p className="movie-info-desc">{this.state.selectedExp.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          show={this.state.consumerAdviceModalSH}
          handleClose={() => {
            this.setState({ consumerAdviceModalSH: true, alertData: {} });
          }}
          title={this.state.consumerAdviceData.title}
          descriptionsList={this.state.consumerAdviceData.description}
          customClasses="consumber-advice-popup"
          icon={false}
          iconWidth={"auto"}
          iconHeight={"0"}
          showCancelBtn={true}
          cancelBtnText="CANCEL"
          showConfirmBtn={true}
          confirmBtnText="I AGREE"
          onConfirm={() => {
            this.iAgreeBtnClick();
          }}
          onCancel={() => {
            this.cancelBtnClick();
          }}
        />
      </>
    );
  }
}

export default index;
