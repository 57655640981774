import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useEffect, useState } from "react";
// import { Tooltip } from "react-tooltip";
import "./index.scss";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

const TicketTypeTooltip = (props) => {
  console.log(props, "Testing props");
  const { ticketTypes, handleSeatSelection, seat, handleApplyToAllSeats, applyToAllSeats, selectedSofaSeatGroupName, show, allowSingleSeatSelection } = props;

  const getSelectedSofaSeat = (seat) => {
    // console.log(seat, "seat", selectedSofaSeatGroupName, "selectedSofaSeatGroupName");
    if (seat.style.toLowerCase() !== "sofa") return;
    let seatGroup = seat.group.filter((seat) => seat.name === selectedSofaSeatGroupName)[0];
    // console.log(seatGroup, "seatGroup");
    return seatGroup;
  };

  const getTooltipTarget = () => {
    if (seat.style.toLowerCase() !== "sofa") {
      return document.getElementById(seat.name);
    } else {
      if (allowSingleSeatSelection === false) {
        return document.getElementById(seat.name);
      } else {
        return document.getElementById(getSelectedSofaSeat(seat)?.name);
      }
    }
  }

  return (
    // <Tooltip openOnClick="true" id={props.id} className="ticket-types-tooltip" clickable={true} isOpen={props.isOpen}>
    //   <div className="content">
    //     <h5>Who is sitting in {seat.style.toLowerCase() === "sofa" ? getSelectedSofaSeat(seat).name : seat.name}?</h5>
    //     <FormControl className="ticket-type-form-control">
    //       <RadioGroup
    //         aria-labelledby="demo-radio-buttons-group-label"
    //         name="radio-buttons-group"
    //       // value={selectedTicketType}
    //       // onChange={handleTicketTypeSelection}
    //       >
    //         {ticketTypes &&
    //           ticketTypes.map((ticketType, index) => {
    //             return (
    //               <FormControlLabel
    //                 key={index}
    //                 value={ticketType.description}
    //                 control={
    //                   <Radio
    //                     size="small"
    //                     sx={{
    //                       color: "white",
    //                       "&.Mui-checked": { color: "white" },
    //                     }}
    //                     o
    //                   />
    //                 }
    //                 label={
    //                   <div className="ticket-type-label">
    //                     <span>{ticketType.description}</span>
    //                     <span>${ticketType.valueBeforeTax.toFixed(2)}</span>
    //                   </div>
    //                 }
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   e.stopPropagation();
    //                   handleSeatSelection(seat, ticketType, getSelectedSofaSeat(seat)?.id);
    //                 }}
    //               />
    //             );
    //           })}
    //         {/* <FormControlLabel value="general" control={<Radio size='small' sx={{
    //                         color: 'white',
    //                         '&.Mui-checked': {
    //                             color: 'white',
    //                         },
    //                     }} />} label={<div className='ticket-type-label'><span>General</span><span>$11.30</span></div>} />
    //                     <FormControlLabel value="child" control={<Radio size='small' sx={{
    //                         color: 'white',
    //                         '&.Mui-checked': {
    //                             color: 'white',
    //                         },
    //                     }} />} label={<div className='ticket-type-label'><span>Child</span><span>$10.08</span></div>} />
    //                     <FormControlLabel value="senior" control={<Radio size='small' sx={{
    //                         color: 'white',
    //                         '&.Mui-checked': {
    //                             color: 'white',
    //                         },
    //                     }} />} label={<div className='ticket-type-label'><span>Senior</span><span>$11.30</span></div>} /> */}
    //       </RadioGroup>
    //     </FormControl>
    //     <p className="note">NOTE: All Rewards member discounts will be applied on the payment screen (if applicable)</p>
    //   </div>
    //   <div className="tooltip-footer">
    //     <FormControlLabel onChange={handleApplyToAllSeats} control={<Checkbox checked={applyToAllSeats} size="small" />} label="Apply to all Seats" />
    //   </div>
    // </Tooltip>
    <Overlay target={getTooltipTarget()} show={show} placement="top" rootClose onHide={() => { props.closeTicketTypeTooltip() }}>
      {(props) => (
        <Tooltip id="overlay-example" className="ticket-types-tooltip" {...props}>
          <div className="content">
            <h5>Who is sitting in {allowSingleSeatSelection ? (seat.style.toLowerCase() === "sofa" ? getSelectedSofaSeat(seat)?.name : seat.name) : seat.name}?</h5>
            <FormControl className="ticket-type-form-control">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              // value={selectedTicketType}
              // onChange={handleTicketTypeSelection}
              >
                {ticketTypes &&
                  ticketTypes.map((ticketType, index) => {
                    if (ticketType.area !== seat.area) return null
                    return (
                      <FormControlLabel
                        key={index}
                        value={ticketType.description}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": { color: "white" },
                            }}
                            o
                          />
                        }
                        label={
                          <div className="ticket-type-label">
                            <span>{ticketType.description}</span>
                            <span>${ticketType.valueBeforeTax.toFixed(2)}</span>
                          </div>
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleSeatSelection(seat, ticketType, getSelectedSofaSeat(seat)?.id);
                        }}
                      />
                    );
                  })}
                {/* <FormControlLabel value="general" control={<Radio size='small' sx={{
                            color: 'white',
                            '&.Mui-checked': {
                                color: 'white',
                            },
                        }} />} label={<div className='ticket-type-label'><span>General</span><span>$11.30</span></div>} />
                        <FormControlLabel value="child" control={<Radio size='small' sx={{
                            color: 'white',
                            '&.Mui-checked': {
                                color: 'white',
                            },
                        }} />} label={<div className='ticket-type-label'><span>Child</span><span>$10.08</span></div>} />
                        <FormControlLabel value="senior" control={<Radio size='small' sx={{
                            color: 'white',
                            '&.Mui-checked': {
                                color: 'white',
                            },
                        }} />} label={<div className='ticket-type-label'><span>Senior</span><span>$11.30</span></div>} /> */}
              </RadioGroup>
            </FormControl>
            <p className="note">NOTE: All Rewards member discounts will be applied on the payment screen (if applicable)</p>
          </div>
          <div className="tooltip-footer">
            <FormControlLabel onChange={handleApplyToAllSeats} control={<Checkbox checked={applyToAllSeats} size="small" />} label="APPLY TO ALL SEATS" />
          </div>
        </Tooltip>
      )}
    </Overlay>
  );
};

export default TicketTypeTooltip;
