import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import "./index.scss";
import CloseIcon from "../../../assets/images/icons/close-icon.svg";
import Form from "react-bootstrap/Form";

const TicketTypeDrawer = (props) => {
  const { ticketTypes, handleSeatSelection, seat, handleApplyToAllSeats, applyToAllSeats, selectedSofaSeatGroupName, show, allowSingleSeatSelection, isOpen, onClose } = props;

  const [voucherNote, setVoucherNote] = useState(process.env.REACT_APP_TICKET_TYPE_NOTE);

  const ticketTypesLayout = process.env.REACT_APP_TICKET_TYPE_LAYOUT;

  const getSelectedSofaSeat = (seat) => {
    // console.log(seat, "seat", selectedSofaSeatGroupName, "selectedSofaSeatGroupName");
    if (seat.style.toLowerCase() !== "sofa") return;
    let seatGroup = seat.group.filter((seat) => seat.name === selectedSofaSeatGroupName)[0];
    // console.log(seatGroup, "seatGroup");
    return seatGroup;
  };

  const getTooltipTarget = () => {
    if (seat.style.toLowerCase() !== "sofa") {
      return document.getElementById(seat.name);
    } else {
      if (allowSingleSeatSelection === false) {
        return document.getElementById(seat.name);
      } else {
        return document.getElementById(getSelectedSofaSeat(seat)?.name);
      }
    }
  };
  return (
    <Drawer open={isOpen} onClose={onClose} direction="bottom" className={`ticket-type-drawer ${isOpen ? "" : "is-hidden"}`}>
      <div className="ticket-type-content">
        <button className="close-btn">
          <img src={CloseIcon} alt="close" onClick={onClose} />
        </button>
        <h3 className="title">who's sitting in {allowSingleSeatSelection ? (seat.style.toLowerCase() === "sofa" ? getSelectedSofaSeat(seat)?.name : seat.name) : seat.name}?</h3>
        <div className={`ticket-type-btn-container ${ticketTypesLayout === "vertical" ? "is-vertical" : "is-horizontal"}`}>
          {ticketTypes &&
            ticketTypes.map((ticketType, ticketTypeIndex) => {
              if (ticketType.area !== seat.area) return null;
              return (
                <button
                  className={`ticket-type-btn`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSeatSelection(seat, ticketType, getSelectedSofaSeat(seat)?.id);
                  }}
                >
                  <span className="ticket-type-desc">{ticketType.description}&nbsp;-&nbsp;</span>
                  <span className="ticket-type-price">${ticketType.valueBeforeTax.toFixed(2)}</span>
                </button>
              );
            })}
        </div>
        {voucherNote && voucherNote.length > 0 && (
          <p className="note">
            <span>NOTE: </span>
            {voucherNote}
          </p>
        )}
        <div className="drawer-footer">
          {/* <FormControlLabel
                        onChange={handleApplyToAllSeats}
                        control={
                            <Checkbox
                                checked={applyToAllSeats}
                                size="small"
                                icon={CheckBoxIcon}
                                checkedIcon={CheckIcon}
                            />
                        }
                        label="Apply to all seats" /> */}
          <Form>
            <Form.Check type="checkbox" id="applyToAllSeats" label="Apply to all seats" checked={applyToAllSeats} onChange={handleApplyToAllSeats} className="apply-to-all-seats-checkbox" />
          </Form>
          {/* <button className="custom-button">
                        continue
                    </button> */}
        </div>
      </div>
    </Drawer>
  );
};

export default TicketTypeDrawer;
